<template>
  <v-dialog
    v-model="showModal"
    max-width="1200"
    max-height="800"
  >
    <v-card>
      <v-toolbar
        dense
        color="#37474F"
      >
        <v-toolbar-title
          class="white--text"
        >
          URL Hint
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          x-large
          color="#ECEFF1"
          text
          @click="showModal=false"
        >
          close
        </v-btn>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-img
              :width="videoWidth"
              :height="videoHeight"
              :src="url"
              contain
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { cdnUrl } from 'src/app-globals';
  export default {
    name: 'UrlHintImagePreview',
    data: () => ({
      showModal: true,
      imageHeight: '',
      imageWidth: '',
      url: `${cdnUrl}/website/youtube-hint-img.png`,
    }),
    computed: {
      videoWidth () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 1000;
        } else {
          return 300;
        }
      },
      videoHeight () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 600;
        } else {
          return 400;
        }
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
  };
</script>
