<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon
        class="icon-style"
        left
        color="#C62828"
      >
        mdi-youtube
      </v-icon> Play Youtube
    </h2>
    <br>
    <v-stepper
      v-model="playYoutube"
      shaped
      class="my-stepper-style"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="playYoutube > 1"
          step="1"
          color="#FF3700"
        >
          Add URL
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="playYoutube > 2"
          step="2"
          color="#FF3700"
        >
          Preview Content
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="playYoutube > 3"
          step="3"
          color="#FF3700"
        >
          Link Device
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <v-form
                ref="form"
                v-model="isFormValid"
              >
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="videoDetails.videoTitle"
                        label="Video Title*"
                        :rules="[(v) => !!v || 'This field is required']"
                        type="text"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="url-div">
                        <div
                          class="div-1"
                        >
                          <v-text-field
                            v-model="videoDetails.videoUrl"
                            label="Youtube Video URL*"
                            outlined
                            dense
                            filled
                            :rules="[(v) => !!v || 'This field is required']"
                          />
                        </div>
                        <div class="div-2">
                          <v-icon
                            color="#37474F"
                            small
                            @click="showUrlHintImg = true"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="videoDetails.urlType"
                        :items="urlTypes"
                        label="URL Type"
                        dense
                        item-text="name"
                        item-value="value"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="videoDetails.videoType"
                        :items="videoTypes"
                        dense
                        label="Video Type"
                        item-text="name"
                        item-value="value"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="'required'"
                        name="From datetime"
                      >
                        <v-datetime-picker
                          v-model="videoDetails.start"
                          label="From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            min: minStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              large
                              color="#37474F"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <v-datetime-picker
                        v-model="videoDetails.end"
                        label="To"
                        :text-field-props="{
                          hint: 'Leave blank if never ends',
                          persistentHint: true,
                          suffix: userTimeZone
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-left"
                    >
                      <v-btn
                        v-if="showScheduleBtn"
                        small
                        dark
                        class="mt-2"
                        color="#FF3700"
                        @click="showAdvancedSchedule = true"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                        Advanced Schedule
                      </v-btn>
                      <advanced-scheduling v-model="showAdvancedSchedule" />
                      <advanced-scheduling-list @show-content-form="showContentForm" />
                      <advanced-schedule-edit-before-creation
                        v-if="showScheduleContent"
                        :editvalues="editScheduleContent"
                        :scheduleindex="scheduleIndex"
                        @closed="showScheduleContent = false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-left"
                    >
                      <v-switch
                        v-model="additionalSettings"
                        label="Additional Setings"
                        color="#FF3700"
                        class="switch-style"
                      />
                    </v-col>
                    <v-container v-if="showAdditionalSettings">
                      <v-card
                        class="my-card-style"
                      >
                        <p class="my-heading">
                          Additional Settings
                        </p>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-checkbox
                              v-model="videoDetails.repeatVideo"
                              label="Repeat Video"
                              color="#FF3700"
                              dense
                              light
                              class="checkbox-style"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-checkbox
                              v-model="videoDetails.showSubtitles"
                              label="Show Subtitles"
                              color="#FF3700"
                              dense
                              light
                              class="checkbox-style"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-checkbox
                              v-model="videoDetails.mute"
                              label="Mute Video"
                              color="#FF3700"
                              dense
                              light
                              class="checkbox-style"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-container>
                  </v-row>
                </v-container>
                <br>
                <p class="require-text-style">
                  *Indicates required field
                </p>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                @click="cancel()"
              >
                cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="#FF3700"
                @click="next()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <review-youtube-video
            v-if="playYoutube >= 2"
            @third-step="linkDigibaord"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <link-digi-boards
            v-if="playYoutube >= 3"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <br>
    <centre-spinner
      :loading="loading"
    />
    <url-hint-image-preview
      v-if="showUrlHintImg"
      @closed="showUrlHintImg = false"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment-timezone';
  import Constants from 'src/constants';
  import AdvancedScheduling from 'src/views/dashboard/component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from 'src/views/dashboard/component/schedules/AdvancedSchedulingList.vue';
  import AdvancedScheduleEditBeforeCreation from 'src/views/dashboard/component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';
  import ReviewYoutubeVideo from './ReviewYoutubeVideo.vue';
  import LinkDigiBoards from './LinkDigiBoards.vue';
  import UrlHintImagePreview from './UrlHintImagePreview.vue';

  export default {
    name: 'CreateNewTicker',
    components: {
      'centre-spinner': spinner,
      AdvancedScheduling,
      AdvancedSchedulingList,
      AdvancedScheduleEditBeforeCreation,
      'review-youtube-video': ReviewYoutubeVideo,
      'link-digi-boards': LinkDigiBoards,
      'url-hint-image-preview': UrlHintImagePreview,
    },
    mixins: [global],
    data () {
      return {
        playYoutube: 1,
        showAdvancedSchedule: false,
        minStartDate: moment().format('YYYY-MM-DD'),
        loading: false,
        showScheduleContent: false,
        editScheduleContent: {},
        scheduleIndex: 0,
        helper: new ModuleHelper(),
        isFormValid: false,
        additionalSettings: false,
        showAdditionalSettings: false,
        videoDetails: {
          videoTitle: null,
          videoUrl: null,
          videoType: 'single-video',
          urlType: null,
          start: '',
          end: null,
          videoStartTime: null,
          videoEndTime: null,
          showSubtitles: false,
          repeatVideo: false,
          videoEmbedUrl: null,
          mute: false,
        },
        videoTypes: [
          {
            name: 'Normal (Single Video)',
            value: 'single-video',
          },
        ],
        urlTypes: [
          {
            name: 'Youtube Video',
            value: 'youtube-video',
          },
          {
            name: 'Youtube Live',
            value: 'youtube-live',
          },
        ],
        showUrlHintImg: false,
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('create-youtube-content') === false || vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_SOCIAL_MEDIA) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      minEndDate () {
        return moment(this.videoDetails.start).format('YYYY-MM-DD');
      },
     advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_TICKER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
      additionalSettings (val) {
        if (val === true) {
          this.showAdditionalSettings = true;
          return;
        }
        this.showAdditionalSettings = false;
      },
    },
    async mounted () {
      this.$store.dispatch('schedule/clearAdvancedSchedule');
    },
    methods: {
      async next () {
        const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=)[a-zA-Z0-9_-]{11}(&t=[0-9]+s?)?$/;
        var validUrl = this.videoDetails.videoUrl.match(regex);
        if (validUrl === null) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Invalid video url.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (this.$refs.form.validate() === false || this.start === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        const videoUrl = this.videoDetails.videoUrl;
        if (this.videoDetails.videoType === 'single-video') {
          const url = videoUrl.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          const videoId = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
          const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=${this.repeatVideoBoolConvert(this.videoDetails.repeatVideo)}&cc_load_policy=${this.showSubtitlesBoolConvert(this.videoDetails.showSubtitles)}&start=${this.videoDetails.videoStartTime}&end=${this.videoDetails.videoEndTime}&mute=${this.isVideoMuted(this.videoDetails.mute)}&enablejsapi=1`;
          this.videoDetails.videoEmbedUrl = embedUrl;
        } else {
          var reg = new RegExp('[&?]list=([a-z0-9_-]+)', 'i');
          var match = reg.exec(videoUrl);

          if (match && match[1].length > 0) {
            const videoId = match[1];
            const embedUrl = `https://www.youtube.com/embed?listType=playlist&list=${videoId}&autoplay=1&loop=${this.repeatVideoBoolConvert(this.videoDetails.repeatVideo)}&cc_load_policy=${this.showSubtitlesBoolConvert(this.videoDetails.showSubtitles)}&start=${this.videoDetails.videoStartTime}&end=${this.videoDetails.videoEndTime}&mute=${this.isVideoMuted(this.videoDetails.mute)}&enablejsapi=1`;
            this.videoDetails.videoEmbedUrl = embedUrl;
          }
        }
        this.$store.dispatch('socialmedia/saveYoutubeVideoForAdding', this.videoDetails);
        setTimeout(() => {
          this.loading = false;
          this.playYoutube = 2;
        }, 1000);
      },
      repeatVideoBoolConvert (boolVal) {
        if (boolVal === false) {
          return 0;
        }
        return 1;
      },
      showSubtitlesBoolConvert (boolVal) {
        if (boolVal === false) {
          return 0;
        }
        return 1;
      },
      isVideoMuted (boolVal) {
        if (boolVal === false) {
          return 0;
        }
        return 1;
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancel () {
        this.$router.push({ name: 'Social Media' });
      },
      linkDigibaord () {
        this.playYoutube = 3;
      },
    },
  };
</script>
<style scoped>
.my-stepper-style {
  background-color: #CFD8DC !important;
  margin-right: 30px;
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}
.checkbox-style{
  margin-top: -20px;
}
::v-deep .checkbox-style .v-label {
 color: #37474F;
}
.require-text-style {
  margin-top: -15px;
  font-style: italic;
}
.switch-style {
  margin-top: -2px;
}
::v-deep .switch-style .v-label {
 color: #37474F;
 font-size: 16px;
}
.my-heading {
  font-size: 20px;
  padding-top: 25px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-card-style {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: -15px;
}
.field-style{
  margin-top: -15px;
}
.url-div {
  display: flex;
}
.div-1 {
  flex-grow: 1;
  margin-right: 5px;
}
.div-2 {
  margin-top: 10px !important;
  cursor: pointer;
}
</style>
