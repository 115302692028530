<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card
      class="review-card"
      shaped
      color="#ECEFF1"
    >
      <div
        v-if="isDetailsStored"
        class="details-div"
      >
        <v-container fluid>
          <v-card
            class="details-card"
            color="#ECEFF1"
          >
            <p
              class="title-style"
            >
              Video Title:-
              <font
                class="font-my-style"
              >
                {{ youtubeVideoForAdding.videoTitle }}
              </font>
            </p>
            <p
              class="date-style"
            >
              Video URL:-
              <font
                class="font-my-style"
              >
                {{ youtubeVideoForAdding.videoUrl }}
              </font>
            </p>
            <p
              class="date-style"
            >
              Start Date:-
              <font
                class="font-my-style"
              >
                {{ youtubeVideoForAdding.start | convertStringToLocalDatetime }}
              </font>
            </p>
            <p
              class="date-style"
            >
              End Date:-
              <font
                v-if="youtubeVideoForAdding.end !== null"
                class="font-my-style"
              >
                {{ youtubeVideoForAdding.end | convertStringToLocalDatetime }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-container>
      </div>
      <div class="iframe-div">
        <iframe
          id="videoId"
          :src="videoEmbedUrl"
          allow="autoplay"
          class="iframe-class"
          frameborder="0"
        />
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-3"
          @click="cancel()"
        >
          cancel
        </v-btn>
        <v-btn
          color="#FF3700"
          @click="next()"
        >
          Next
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import moment from 'moment';

  export default {
    name: 'Support',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
      };
    },
    computed: {
      youtubeVideoForAdding () {
        return this.$store.getters['socialmedia/getYoutubeVideoForAdding'];
      },
      isDetailsStored () {
        if (Object.keys(this.youtubeVideoForAdding).length > 0) {
          return true;
        }
        return false;
      },
      videoEmbedUrl () {
        if (this.isDetailsStored === true && this.youtubeVideoForAdding.videoEmbedUrl !== null) {
          return this.youtubeVideoForAdding.videoEmbedUrl;
        }
        return '';
      },
    },
    methods: {
      cancel () {
        this.$router.push({ name: 'Social Media' });
      },
      next () {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          const video = document.getElementById('videoId');
          video.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*');
          this.$emit('third-step');
        }, 2000);
      },
    },
  };
</script>
<style scoped>
.review-card {
  margin-top: -10px;
  padding-top: 25px;
  padding-bottom: 20px;
}
.iframe-div {
  width:100%;
  text-align:center;
}
.iframe-class {
  width: 700px;
  height: 350px;
}
@media (max-width: 480px) {
  .iframe-class {
    width: 200px;
    height: 150px;
  }
}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .iframe-class {
    width: 450px;
    height: 220px;
  }
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
  .iframe-class {
    width: 500px;
    height: 250px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
  .iframe-class {
    width: 700px;
    height: 350px;
  }
}
/* Media Query for Large screens */
@media (min-width: 1400px) {
  .iframe-class {
    width: 1200px;
    height: 600px;
  }
}
.title-style {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.font-my-style {
  font-weight: bold;
  font-size: 17px;
  color: #37474F;
}
.date-style {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  margin-top: -10px;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.details-div {
  margin-top: -30px;
  padding-bottom: 10px;
}
.details-card {
  padding-top: 15px;
  padding-bottom: 5px;
  border-left: 1px solid #37474F !important;
}
</style>
